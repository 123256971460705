<template>
  <v-card>
    <v-card-title class="text-h5">
      Public Api Usage
    </v-card-title>
    <v-divider />
    <v-card-text>
      View your api usage for the selected month and year.
    </v-card-text>
    <v-spacer />
    <v-card-actions class="flex-row-reverse">
      <v-dialog
        v-model="downloadFilesMenu"
        max-width="400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            view
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Select Year and Month</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  sm="8"
                  md="6"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-form ref="form">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          :rules="rules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </v-form>
                    </template>
                    <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="tertiary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="success"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-expand-transition>
            <div v-show="show">
              <v-card class="ml-1 mr-1">
                <v-row
                  no-gutters
                  justify="space-between"
                  class="ml-3 mr-3 mb-3"
                >
                  <v-col cols="6">
                    <span class="font-weight-bold">Total Matched:</span> {{ results.totalMatched }}
                  </v-col>
                  <v-col cols="6">
                    <span class="font-weight-bold">Total Non Matched:</span> {{ results.totalNonMatched }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row
                  no-gutters
                  justify="space-between"
                  class="ml-3 mr-3 mb-3"
                >
                  <v-col cols="6">
                    <v-card-text>
                      <p class="font-weight-bold">
                        Matched Passes:
                      </p>
                      <v-list-item-content
                        v-for="(item, i) in results.matchedPasses"
                        :key="i"
                      >
                        <v-list-item-subtitle><span class="font-weight-medium">Pass Number: </span>{{ item.passNumber }}</v-list-item-subtitle>
                        <v-list-item-subtitle><span class="font-weight-medium">Count: </span>{{ item.count }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-card-text>
                      <p class="font-weight-bold">
                        Non Matched Passes:
                      </p>
                      <v-list-item-content
                        v-for="(item, i) in results.nonMatchedPasses"
                        :key="i"
                      >
                        <v-list-item-subtitle><span class="font-weight-medium">Pass Number: </span>{{ item.passNumber }}</v-list-item-subtitle>
                        <v-list-item-subtitle><span class="font-weight-medium">Count: </span>{{ item.count }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-expand-transition>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="tertiary"
              :disabled="reportLoading"
              @click="resetDates()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              :loading="reportLoading"
              :disabled="isDisabled"
              @click="getPubApiUsageReport()"
            >
              Show
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { pubapiService } from '@/shared/services'
import { downloadFileMixin } from '@/shared/mixins/general'

export default {
  name: 'PubApiUsageReport',

  mixins: [downloadFileMixin],

  data () {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 7),
      reportLoading: false,
      downloadFilesMenu: false,
      show: false,
      results: [],
      rules: [
        value => !!value || 'Required.'
      ]
    }
  },

  computed: {
    ...mapGetters(['simulatedCustomerNowwId']),
    isDisabled () {
      return this.startDate === null
    }
  },

  methods: {
    async getPubApiUsageReport () {
      var _this = this
      this.reportLoading = true
      var splitDate = this.date.split('-')
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      await pubapiService.getPubApiUsageReport(splitDate[1], splitDate[0], customerNowwId.toString().padStart(5, '0'))
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
          }
        })
        .finally(() => {
          this.reportLoading = false
          this.show = true
        })
    },

    resetDates () {
      this.$refs.form.reset()
      this.date = new Date().toISOString().substr(0, 7)
      this.show = false
      this.downloadFilesMenu = false
    }
  }
}

</script>
